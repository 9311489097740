import request from "@/utils/request";
const url =
  process.env.NODE_ENV === "development" //development开发模式
    ? "/api/"
    : "http://api.ruandy.com/ketang_v1.0/api/admin/";
const url2 =
  process.env.NODE_ENV === "development" 
    ? "/resource/"
    : "http://api.ruandy.com/ketang_v1.0/api/teacher/";

// 题库题目列表
export function question_detail_index(data) {
  return request({
    url: url + "questionbank/question_detail_index",
    method: "post",
    data,
  });
}
//满意度获取老师列表
export function getTeacher(data) {
  return request({
    url: url + "satisfactionlevel/getTeacher",
    method: "post",
    data,
  });
}
//提交满意度
export function addSatisfactionlevel(data) {
  return request({
    url: url + "satisfactionlevel/add_satisfaction_level",
    method: "post",
    data,
  });
}

// 获取验证码
export function getCode(data) {
  return request({
    url: url + "getCode",
    method: "post",
    data,
  });
}
// runCode
export function runCode(data) {
  return request({
    url: url + "runCode",
    method: "post",
    data,
  });
}
// 验证码登录
export function login(data) {
  return request({
    url: url + "login/index",
    method: "post",
    data,
  });
}
// 老师作业评论
export function commentList(data) {
  return request({
    url: url + "commentList",
    method: "post",
    data,
  });
}
// 密码登录
export function passwordLogin(params) {
  return request({
    url: url + "passwordLogin",
    method: "get",
    params,
  });
}
// 文件列表
export function fileList(data) {
  return request({
    url: url + "fileList",
    method: "post",
    data,
  });
}
// 消息已读按钮
export function messageRead(data) {
  return request({
    url: url + "messageRead",
    method: "post",
    data,
  });
}
// 观看时间记录
export function lookRecord(data) {
  return request({
    url: url + "student_video_viewing_records",
    method: "post",
    data,
  });
}

// 退出登录
export function outLogin(data) {
  return request({
    url: url + "outLogin",
    method: "post",
    data,
  });
}
// 我的积分
export function getIntegral(data) {
  return request({
    url: url + "integral",
    method: "post",
    data,
  });
}
// 获取与修改个人信息
export function editUserGet(params) {
  return request({
    url: url + "editUser",
    method: "get",
    params,
  });
}
// 作业列表
export function jobList(data) {
  return request({
    url: url + "jobList",
    method: "post",
    data,
  });
}
// 作业详情
export function JobDetails(data) {
  return request({
    url: url + "JobDetails",
    method: "post",
    data,
  });
}
// 提交作业
export function jobSubmit(data) {
  return request({
    url: url + "jobSubmit",
    method: "post",
    data,
  });
}
// 提交作业2
export function jobSubmits(data) {
  return request({
    url: url + "jobSubmits",
    method: "post",
    data,
  });
}
// 提交作业
export function uploadeFile(data) {
  return request({
    url: url + "uploadeFile",
    method: "post",
    data,
  });
}
// 提交题目
export function grade_question_detail_answer(data) {
  return request({
    url: url + "questionbank/grade_question_detail_answer",
    method: "post",
    data,
  });
}
// 班级题库列表
export function questionbank_index(data) {
  return request({
    url: url + "questionbank/index",
    method: "post",
    data,
  });
}
// 获取题目详情（包含题目列表）
export function grade_question_detail(data) {
  return request({
    url: url + "questionbank/grade_question_detail",
    method: "post",
    data,
  });
}
// 题库题目题解列表
export function question_detail_tj(data) {
  return request({
    url: url + "questionbank/grade_question_detail_tj",
    method: "post",
    data,
  });
}
// 考试列表
export function examinationList(data) {
  return request({
    url: url + "paper/examinationList",
    method: "post",
    data,
  });
}
// 试题列表
export function questionsList(data) {
  return request({
    url: url + "paper/questionsList",
    method: "post",
    data,
  });
}
// 答案提交
export function answerSheet(data) {
  return request({
    url: url + "paper/answerSheet",
    method: "post",
    data,
  });
}
// 提交试卷
export function postPaper(data) {
  return request({
    url: url + "paper/postPaper",
    method: "post",
    data,
  });
}
// 查看学生成绩报告
export function bulletin(data) {
  return request({
    url: url + "paper/bulletin",
    method: "post",
    data,
  });
}
// 修改个人信息
export function editUser(data) {
  return request({
    url: url + "editUser",
    method: "post",
    data,
  });
}
// 首页统计
export function statistics(data) {
  return request({
    url: url + "statistics",
    method: "post",
    data,
  });
}
// 商家列表
export function companylist(data) {
  return request({
    url: url + "companylist",
    method: "post",
    data,
  });
}
// 添加商家
export function addCompany(data) {
  return request({
    url: url + "addCompany",
    method: "post",
    data,
  });
}
// 停启用商家
export function stopCompany(data) {
  return request({
    url: url + "stopCompany",
    method: "post",
    data,
  });
}
// 获取商家信息
export function editCompanyGet(params) {
  return request({
    url: url + "editCompany",
    method: "get",
    params,
  });
}
// 编辑商家信息
export function editCompanyPost(data) {
  return request({
    url: url + "editCompany",
    method: "post",
    data,
  });
}
// 删除商家
export function delCompany(data) {
  return request({
    url: url + "delCompany",
    method: "post",
    data,
  });
}
// 获取权限列表
export function roleList(data) {
  return request({
    url: url + "roleList",
    method: "post",
    data,
  });
}
// 管理员列表
export function managelist(data) {
  return request({
    url: url + "managelist",
    method: "post",
    data,
  });
}
// 添加管理员
export function addmanager(data) {
  return request({
    url: url + "addmanager",
    method: "post",
    data,
  });
}
// 获取管理员信息
export function editmanagerGet(params) {
  return request({
    url: url + "editmanager",
    method: "get",
    params,
  });
}
// 修改管理员信息
export function editmanagerPost(data) {
  return request({
    url: url + "editmanager",
    method: "post",
    data,
  });
}
// 管理员删除
export function delmanager(data) {
  return request({
    url: url + "delmanager",
    method: "post",
    data,
  });
}
// 停用管理员
export function stopManager(data) {
  return request({
    url: url + "stopManager",
    method: "post",
    data,
  });
}
// 职位列表
export function positionList(data) {
  return request({
    url: url + "positionList",
    method: "post",
    data,
  });
}
// 添加职位
export function positionAdd(data) {
  return request({
    url: url + "positionAdd",
    method: "post",
    data,
  });
}
// 获取报名人数上限
export function applyNumEdit(params) {
  return request({
    url: url + "applyNumEdit",
    method: "get",
    params,
  });
}
// 修改报名人数上限
export function applyNumPost(data) {
  return request({
    url: url + "applyNumEdit",
    method: "post",
    data,
  });
}
// 修改报名人数上限
export function positionAudit(data) {
  return request({
    url: url + "positionAudit",
    method: "post",
    data,
  });
}
// 获取职位信息
export function positionEdit(params) {
  return request({
    url: url + "positionEdit",
    method: "get",
    params,
  });
}
// 修改职位信息
export function positionPost(data) {
  return request({
    url: url + "positionEdit",
    method: "post",
    data,
  });
}
// 职位停用或启用
export function positionStartStop(data) {
  return request({
    url: url + "positionStartStop",
    method: "post",
    data,
  });
}
// 招聘类型列表
export function recruitTypeList(data) {
  return request({
    url: url + "RecruitTypeList",
    method: "post",
    data,
  });
}
